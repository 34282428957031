<template>
  <div class="resList">
    <!-- <div class="topBar" :style="{backgroundColor: '#fff'}">
      <van-row>
        <van-col span="6"><div @click="backTop"><van-icon name="arrow-left"/></div></van-col>
        <van-col span="12"><span class="pageTitle">选择走访人</span></van-col>
        <van-col span="6"></van-col>
      </van-row>
    </div> -->
    <div class="search">
      <input v-model="searchValue" type="text" placeholder="输入姓名搜索" class="search-content">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <van-list v-if="searchList.length > 0" offset="10">
      <van-checkbox-group v-model="checkedWorker">
        <van-checkbox v-for="item in searchList" :name="item.value" :key="item.value">
          <div style="width:160px;display:inline-block">{{item.label}}</div>
          <template #icon="props">
            <img class="img-icon" :src="props.checked ? require('@/assets/img/select.png') : ''" />
          </template>
        </van-checkbox>
      </van-checkbox-group>
    </van-list>
    <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
    <van-row>
      <van-col :span="24" style="position: fixed;bottom: 0">
        <van-button type="info" size="large" round @click="checkWorker">确定</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      searchValue: '',
      dataList: [],
      searchList: [],
      checkedWorker: [],
      checkWorkerList: []
    }
  },
  created () {
    if (this.$store.state.workerList.length > 0) {
      this.checkedWorker = this.$store.state.workerList.map(item => {
        return item.value
      })
      this.checkWorkerList = this.$store.state.workerList
    }
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    })
    this.getDataList()
  },
  methods: {
    ...mapMutations,
    backTop () {
      this.$emit('checkWorker')
    },
    onSearch () {
      this.searchList = this.dataList.filter(item => {
        return item.label.indexOf(this.searchValue) > -1
      })
      console.log(this.searchList)
    },
    onCancel () {
      this.searchValue = ''
      this.searchList = this.dataList
    },
    getDataList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/orgUserManagerTree'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$globalData.userInfo.orgId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          let  list = []
          data.userManagerTree.map(item => {
            let  listTemp = item.children.filter(item => {
              return item.value != this.$globalData.userInfo.userId
            })
            listTemp.forEach(item=>{
                list.push(item)
            })
          })
          let map = new Map()
          list.forEach((item,index)=>{
            if (!map.has(item.value)){
              map.set(item.value,item)
            }
          })
          this.dataList = [...map.values()]
          this.searchList = [...map.values()]
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    checkWorker () {
      let workerIds = this.searchList.map(item => {return item.value}).toString()
      let unSelectedList = this.checkWorkerList.filter(item => {
        return workerIds.indexOf(item.value) === -1
      })
      this.checkWorkerList = this.searchList.filter(item => {
        return this.checkedWorker.indexOf(item.value) !== -1
      }).concat(unSelectedList)
      this.$store.commit('setWorkerList', this.checkWorkerList)
      this.$router.go(-1)
      // this.$emit('checkWorker', this.checkWorkerList)
    }
  }
}
</script>

<style lang="scss" scoped>
.van-icon  {
  font-size: 40px;
  line-height: 80px;
  text-align: center;
}
.topBar {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 100px;
  padding: 0 44px;
  .pageTitle {
    display: block;
    text-align: center;
    font-size: 36px;
    color: #333;
    font-family: PingFang SC;
    // font-weight: bold;
  }
}
.resList {
  position: absolute;
  // top: 100px;
  width: 750px;
  background-color: #f5f5f5;
  .search {
    background-color: #f5f5f5;
    width: 750px;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .search-content {
      font-size: 24px;
      width: 552px;
      height: 60px;
      border-radius: 40px;
      border: 1px solid #E8E8E8;
      padding-left: 30px;
      position: relative;
      &::placeholder {
        color: #999999;
        font-size: 24px;
      }
    }
    .search-clear {
      width: 22px;
      height: 22px;
      position: absolute;
      right: 200px;
      top: 40px;
    }
    .search-btn {
      font-size: 22px;
      width: 122px;
      line-height: 60px;
      text-align: center;
      border-radius: 60px;
      color: #fff;
      background-color: #387FF5;
    }
  }
  .img-icon {
    width: 29px;
    height: 25px;
  }
}
.van-search__content {
  border-radius: 30px;
}
::v-deep .van-checkbox__icon {
  position: absolute;
  right: 84px;
}
::v-deep .van-list {
  padding: 0 24px;
  margin: 0 30px 110px;
  border-radius: 10px;
  background-color: #fff;
}
::v-deep .van-checkbox {
  height: 80px;
  border-bottom: 1px solid #f5f5f5;
}
::v-deep .van-checkbox__label {
  font-size: 28px;
}
</style>
