<template>
  <div class="resList">
    <!-- <div class="topBar" :style="{backgroundColor: '#fff'}">
      <van-row>
        <van-col span="6"><div @click="backTop"><van-icon name="arrow-left"/></div></van-col>
        <van-col span="12"><span class="pageTitle">选择居民</span></van-col>
        <van-col span="6"></van-col>
      </van-row>
    </div> -->
    <div class="search">
      <input v-model="searchValue" type="text" placeholder="输入姓名或身份证号搜索" class="search-content">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
      <van-checkbox-group v-model="checkedRes">
        <van-checkbox v-for="item in dataList" :name="item.id" :key="item.id">
          <div style="width:80px;display:inline-block">{{item.name}}</div><div style="display:inline-block">{{item.idNumber}}</div>
          <template #icon="props">
            <img class="img-icon" :src="props.checked ? require('@/assets/img/select.png') : ''" />
          </template>
        </van-checkbox>
      </van-checkbox-group>
    </van-list>
    <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
    <van-row>
      <van-col :span="24" style="position: fixed;bottom: 0">
        <van-button type="info" size="large" round @click="checkRes">确定</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  data () {
    return {
      page: 0,
      searchValue: '',
      dataList: [],
      loading: false,
      finished: false,
      checkedRes: [],
      checkResList: []
    }
  },
  created () {
    if (this.$store.state.resList.length > 0) {
      this.checkedRes = this.$store.state.resList.map(item => {
        return item.id
      })
      this.checkResList = this.$store.state.resList

    }
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    })
    this.getDataList()
  },
  methods: {
    ...mapMutations,
    backTop () {
      this.$emit('checkRes')
    },
    onSearch () {
      this.page = 0
      this.finished = false
      this.dataList = []
      this.getDataList()
    },
    onCancel () {
      this.page = 0
      this.finished = false
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      let idNumber = ''
      let name = ''
      // 判断搜索条件为姓名还是身份证
      if (parseInt(this.searchValue.substr(0, 1))) {
        idNumber = this.searchValue
      } else {
        name = this.searchValue
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: 20,
          orgId: this.$orgId,
          name: name,
          idNumber: idNumber,
          haveHouse : 0
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = this.dataList.concat(data.page.list)
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.loading = false
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    checkRes () {
      let resIds = this.dataList.map(item => {return item.id}).toString()
      let unSelectedList = this.checkResList.filter(item => {
        return resIds.indexOf(item.id) === -1
      })
      this.checkResList = this.dataList.filter(item => {
        return this.checkedRes.indexOf(item.id) !== -1
      }).concat(unSelectedList)
      this.$store.commit('setResList', this.checkResList)
      this.$router.go(-1)
      // this.$emit('checkRes', this.checkResList)
    }
  }
}
</script>

<style lang="scss" scoped>
.van-icon  {
  font-size: 40px;
  line-height: 80px;
  text-align: center;
}
.topBar {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 100px;
  padding: 0 44px;
  .pageTitle {
    display: block;
    text-align: center;
    font-size: 36px;
    color: #333;
    font-family: PingFang SC;
    // font-weight: bold;
  }
}
.resList {
  position: absolute;
  // top: 100px;
  width: 750px;
  background-color: #f5f5f5;
  .search {
    background-color: #f5f5f5;
    width: 750px;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .search-content {
      font-size: 24px;
      width: 552px;
      height: 60px;
      border-radius: 40px;
      border: 1px solid #E8E8E8;
      padding-left: 30px;
      position: relative;
      &::placeholder {
        color: #999999;
        font-size: 24px;
      }
    }
    .search-clear {
      width: 22px;
      height: 22px;
      position: absolute;
      right: 200px;
      top: 40px;
    }
    .search-btn {
      font-size: 22px;
      width: 122px;
      line-height: 60px;
      text-align: center;
      border-radius: 60px;
      color: #fff;
      background-color: #387FF5;
    }
  }
  .img-icon {
    width: 29px;
    height: 25px;
  }
}
.van-search__content {
  border-radius: 30px;
}
::v-deep .van-checkbox__icon {
  position: absolute;
  right: 84px;
}
::v-deep .van-list {
  padding: 0 24px 100px;
  margin: 0 30px;
  border-radius: 10px;
  background-color: #fff;
}
::v-deep .van-checkbox {
  height: 80px;
  border-bottom: 1px solid #f5f5f5;
}
::v-deep .van-checkbox__label {
  font-size: 28px;
}
</style>
