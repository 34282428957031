<template>
  <div>
    <div v-show="visible">

      <van-popup v-model="buildTypeShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.buildTypeList" value-key="label"
                    @confirm="buildTypeConfirm" @cancel="buildTypeShow = false"/>
      </van-popup>

      <van-popup v-model="visitWayShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.visitWayList" value-key="label"
                    @confirm="visitWayConfirm" @cancel="visitWayShow = false"/>
      </van-popup>

      <van-popup v-model="visitTimeShow" position="bottom">
        <van-datetime-picker v-model="submitData.visitTime" type="datetime" title="请选择" :formatter="formatDate"
                             @cancel="visitTimeShow = false" @confirm="timeConfirm" :maxDate="maxDate"/>
      </van-popup>

      <van-popup v-model="visitTypeShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.visitTypeList" value-key="label"
                    @confirm="visitTypeConfirm" @cancel="visitTypeShow = false"/>
      </van-popup>

      <!-- 选择房屋 -->
      <van-popup v-model="buildingShow" position="bottom">
        <van-cascader v-model="submitData.building" title="请选择" :options="selectData.buildingList"
                      @close="buildingShow = false" @finish="buildingFinish" :field-names="fieldNames"/>
      </van-popup>

      <van-popup v-model="problemTypeShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.visitWayList" value-key="label"
                    @confirm="visitWayConfirm" @cancel="visitWayShow = false"/>
      </van-popup>

      <!-- 问题来源 -->
      <van-popup v-model="sourceTypeShow" position="bottom">
        <van-picker title="请选择" show-toolbar :columns="selectData.sourceTypeList" value-key="label"
                    @confirm="sourceTypeConfirm" @cancel="sourceTypeShow = false"/>
      </van-popup>

      <!-- 问题类型 -->
      <van-popup v-model="problemTypeShow" position="bottom">
        <van-cascader title="请选择" :options="selectData.problemList"
                      @close="problemTypeShow = false" @finish="problemTypeFinish" :field-names="fieldNames"/>
      </van-popup>

      <van-tabs v-model="activeName" :class="submitData.id ? '' : 'tab'" color="#4581F8">
        <van-tab title="走访详情" :name="'tab1'" class="info">
          <van-cell-group class="visitInfo" v-show="visible">
            <van-cell>
              <template #title>
                <!--            <div class="blue-box"></div>-->
                <span class="custom-title" style="font-weight: 600;color: #333333;">基础信息</span>
              </template>
            </van-cell>
            <van-cell title="房屋类别" :value="selectMsg.buildTypeStr" @click="!isTasks?buildTypeShow = !submitData.id : ''"
                      :value-class="{'value-common':selectMsg.buildTypeStr=='请选择'}" :is-link="isEdit"/>
            <van-cell v-if="submitData.id&&submitData.buildType!=3">
              <template #title>
                <span class="custom-title">走访居民</span>
                <div>
                  <van-button v-for="val in typeList[0].resList" plain round type="info" size="small" :key="val.id"
                              style="margin:0 6px 6px 0;padding: 0 10px;background: rgba(0, 122, 255, 0.1);">{{ val.name }}
                  </van-button>
                </div>
              </template>
            </van-cell>
            <van-cell title="详细地址" v-if="submitData.id&&submitData.buildType!=2">
              <van-field clearable clear-trigger="always" v-model="submitData.address" readonly input-align="right"/>
            </van-cell>
            <van-cell title="服务方式" :value="selectMsg.visitWayStr" @click="visitWayShow = isEdit"
                      :value-class="{'value-common':selectMsg.visitWayStr=='请选择'}" :is-link="isEdit"/>
            <van-cell title="走访时间" :value="selectMsg.visitTime" @click="visitTimeShow = isEdit"
                      :value-class="{'value-common':selectMsg.visitTime=='请选择'}" :is-link="isEdit"/>
            <!-- 有房户 -->
            <template v-if="submitData.buildType == 1">
              <van-cell title="详细地址" :value="selectMsg.buildingStr" @click="!isTasks?buildingShow = !submitData.id: ''"
                        v-if="!submitData.id" :value-class="{'value-common':selectMsg.buildingStr=='请选择'}"
                        :is-link="isEdit"/>
              <van-cell v-if="submitData.houseId" title="查看户信息" value="查看" value-class="check">
                <van-button type="info" plain size="normal" @click="toHouseInfo">查看</van-button>
              </van-cell>
              <van-notice-bar left-icon="volume-o" :scrollable="false"
                              text="小提示：选择多个走访类型可以同时完成多个走访" v-if="multipleTypeShow && !isTasks" style="margin-bottom: 8px"/>
              <van-tabs v-model="active" v-if="multipleTypeShow" @change="tabsChange">
                <template v-for="(item, index) in typeList">
                  <van-tab :title="item.visitTypeName" :key="item.visitType" class="tabFont"
                           >
                    <van-button v-for="(val, i) in item.resList" :type="val.select?'info':'default'"
                                :class="val.select?'checkClass':''"
                                size="small" :key="val.id" class="userBtn" @click="resClick(index, i)">{{ val.name }}
                    </van-button>
                    <van-cell title="走访描述" :border="false" class="cellClass"></van-cell>
                    <van-field v-model="item.visitDes" type="textarea" rows="3" :placeholder="item.placeholder"
                               input-align="left" class="left"
                               :class="item.placeholderClass" clearable clear-trigger="always"
                               style="background-color: #F5F5F5;border-radius: 10px;padding: 10px 20px 0;margin-top: 10px;font-size: 14px"></van-field>
                    <van-cell :is-link="isEdit" @click="selectWorker(index)" class="cellClass"
                              :value="item.workUser.length==0?'请选择':null"
                              :value-class="{'value-common':item.workUser.length==0}">
                      <template #title>
                        <span>陪访人</span>
                        <div>
                          <van-button v-for="val in item.workUser" plain round type="info" size="small" :key="val.value"
                                      style="margin-right: 4px;background: rgba(0, 122, 255, 0.1);">{{ val.label }}
                          </van-button>
                        </div>
                      </template>
                    </van-cell>
                    <van-cell title="走访照片" :border="false" class="cellClass"></van-cell>
                    <van-cell style="padding: 0 5px">
                      <van-row v-if="isEdit">
                        <van-col :span="24">
                          <!--                    <van-uploader v-model="item.visitPicture" :name="index" multiple @delete="uploaddel"-->
                          <!--                                  :after-read="uploadRead" :before-read="beforeUpload"/>-->
                          <upload-file
                              :file-list="item.visitPicture"
                              :del-btn-show="isEdit"
                              :upload-btn-show="isEdit"
                              :query="index"
                              @filesUpload="upload"
                              @delFile="delFile"
                              :upload-path="'files/visitPicture/' + orgId"
                              :file-type="'all'"/>
                        </van-col>
                      </van-row>
                    </van-cell>
                  </van-tab>
                </template>
              </van-tabs>
              <template v-if="singleTypeShow">
                <van-cell-group>
                  <van-cell v-if="!submitData.id">
                    <template #title>
                      <span class="custom-title">选择居民</span>
                      <div>
                        <van-button v-for="(val, i) in typeList[0].resList" plain round :type="val.select?'info':'default'"
                                    :style="{background:val.select?'rgba(0, 122, 255, 0.1)':''}"
                                    size="small" :key="val.id" style="margin:0 6px 6px 0;padding: 0 10px;"
                                    @click="resClick(0, i)">{{ val.name }}
                        </van-button>
                      </div>
                    </template>
                  </van-cell>
                  <van-cell title="走访类别" :value="selectMsg.visitTypeStr"
                            @click="visitTypeShow = visitTypeFlag ? false : isEdit"
                            :value-class="{'value-common':selectMsg.visitTypeStr=='请选择'}" :is-link="isEdit"/>
                  <van-field
                      clearable
                      clear-trigger="always"
                      :readonly="!isEdit"
                      :border="false"
                      class="left"
                      v-model="typeList[0].visitDes"
                      rows="3"
                      autosize
                      type="textarea"
                      :placeholder="typeList[0].placeholder"
                      input-align="left"
                      :class="typeList[0].placeholderClass"
                  />
                </van-cell-group>
              </template>

            </template>
            <!-- 无房户 -->
            <template v-if="submitData.buildType == 2">
              <van-cell-group>
                <van-cell :is-link="isEdit" @click="selectRes" v-if="!submitData.id && !username"
                          :value="checkedRes.length==0?'请选择':null" :value-class="{'value-common':checkedRes.length==0}">
                  <template #title>
                    <span class="custom-title">走访居民</span>
                    <div>
                      <van-button v-for="item in checkedRes" plain type="info" size="small" :key="item.id"
                                  style="margin-right: 4px;background: rgba(0, 122, 255, 0.1);">{{ item.name }}
                      </van-button>
                    </div>
                  </template>
                </van-cell>
                <van-cell title="走访居民" :value="username" v-if="username !== undefined && username !== ''"/>
                <van-cell title="走访类别" :value="selectMsg.visitTypeStr" @click="visitTypeShow = isEdit"
                          :value-class="{'value-common':selectMsg.visitTypeStr=='请选择'}" :is-link="isEdit"/>
                <van-field
                    clearable
                    clear-trigger="always"
                    :readonly="!isEdit"
                    :border="false"
                    class="left"
                    v-model="typeList[0].visitDes"
                    rows="3"
                    autosize
                    type="textarea"
                    :placeholder="typeList[0].placeholder"
                    input-align="left"
                    :class="typeList[0].placeholderClass"
                />
              </van-cell-group>
            </template>
            <template v-if="submitData.buildType == 3">
              <van-cell-group>
                <van-cell title="选择场所" :value="selectMsg.placeStr" @click="selectPlace" v-if="!submitData.id"
                          :value-class="{'value-common':selectMsg.placeStr=='请选择'}" :is-link="isEdit"/>
                <van-cell title="走访类别" :value="selectMsg.visitTypeStr" @click="visitTypeShow = isEdit"
                          :value-class="{'value-common':selectMsg.visitTypeStr=='请选择'}" :is-link="isEdit"/>
                <van-field
                    clearable
                    clear-trigger="always"
                    :readonly="!isEdit"
                    :border="false"
                    class="left"
                    v-model="typeList[0].visitDes"
                    rows="3"
                    autosize
                    type="textarea"
                    :placeholder="typeList[0].placeholder"
                    input-align="left"
                    :class="typeList[0].placeholderClass"
                />
              </van-cell-group>
            </template>
            <van-cell :is-link="isEdit" @click="selectWorker(false)"
                      v-if="!submitData.id&&(submitData.buildType==2||submitData.buildType==3||singleTypeShow)"
                      :value="checkedWorker.length==0?'请选择':null" :value-class="{'value-common':checkedWorker.length==0}">
              <template #title>
                <span class="custom-title">陪访人</span>
                <div>
                  <van-button v-for="item in checkedWorker" plain round type="info" size="small" :key="item.value"
                              style="margin-right: 4px;background: rgba(0, 122, 255, 0.1);">{{ item.label }}
                  </van-button>
                </div>
              </template>
            </van-cell>
            <van-cell v-if="submitData.id">
              <template #title>
                <span class="custom-title">陪访人</span>
                <div>
                  <van-button v-for="val in createUserName" plain round type="info" size="small" :key="val.id"
                              style="margin:0 6px 6px 0;padding: 0 10px;background: rgba(0, 122, 255, 0.1);">{{ val }}
                  </van-button>
                </div>
              </template>
            </van-cell>
            <van-row v-if="!multipleTypeShow">
              <van-col :span="24">
                <!--            <van-uploader v-model="typeList[0].visitPicture" :deletable="isEdit" :show-upload="isEdit" multiple-->
                <!--                          @delete="uploaddel" :after-read="uploadRead" :before-read="beforeUpload"/>-->
                <div class="upload">
                  <upload-file
                      :file-list="typeList[0].visitPicture"
                      :del-btn-show="isEdit"
                      :upload-btn-show="isEdit"
                      :query="0"
                      @filesUpload="upload"
                      @delFile="delFile"
                      :upload-path="'files/visitPicture/' + orgId"
                      :file-type="'all'"/>
                </div>
              </van-col>
            </van-row>

            <van-cell title="是否需要二维码填写" v-if="this.street == 12985">
              <!-- <van-cell title="是否需要二维码填写" > -->
              <van-switch v-model="codeSubmit" size="24px"/>
            </van-cell>
            <van-cell-group>
              <van-cell v-if="!codeSubmit || this.street != 12985 ||!isEdit">
                <!-- <van-cell v-if="!codeSubmit"> -->
                <template #title>
                  <span class="custom-title">走访满意度</span>
                  <van-radio-group v-model="submitData.visitSatisfaction" direction="horizontal" :disabled="!isEdit">
                    <van-radio name=0>不满意</van-radio>
                    <van-radio name=1>满意</van-radio>
                    <van-radio name=2>非常满意</van-radio>
                  </van-radio-group>
                </template>
              </van-cell>
            </van-cell-group>
          </van-cell-group>
          <van-cell-group v-if="submitData.buildType!=2 && isOpen && !submitData.id" class="info-second">
            <van-cell title="未处理问题数" :value="problemIds && problemIds.length > 0 ? problemIds.length + '个' : 0 + '个'" value-class="check" @click="toProblem"/>
          </van-cell-group>
          <van-cell-group v-if="isOpen && !submitData.id" class="info-second">
            <van-cell title="是否需要上报问题" center>
              <van-switch v-model="problemSubmit" size="24px" @change="problemSubmitShow" :disabled="!!submitData.id"/>
            </van-cell>
          </van-cell-group>

          <template v-if="problemSubmit">
            <van-cell-group v-for="(item, index) in submitData.problemList" :key="index" class="info-second">
              <van-cell :title="'问题'+(index+1)" v-if="!submitData.id">
                <van-image :src="require('@/assets/img/problem_add.png')" height="25" width="25"
                           style="margin-right: 10px" @click="addProblem"/>
                <van-image :src="require('@/assets/img/problem_delete.png')" height="25" width="25"
                           style="margin-right: 10px" @click="deleteProblem(index)"/>
                <van-image :src="require(`@/assets/img/problem_${problemShow?'shrink':'open'}.png`)" height="25"
                           width="25" @click="problemShow=!problemShow"/>
              </van-cell>
              <div v-show="problemShow">
                <van-cell title="处理状态" v-if="submitData.id">
                  <van-field clearable clear-trigger="always" v-model="item.status" readonly input-align="right"/>
                </van-cell>
                <van-cell title="问题来源" :value="item.sourceTypeStr" @click="!!submitData.id ? false : checkSourceType(index)"/>
                <van-cell title="问题类型" :value="item.typeTitle" @click="!!submitData.id ? false : checkProblemType(index)"/>
                <van-cell title="是否关联此次走访" center>
                  <van-switch v-model="item.isRelation" size="24px" :disabled="!!submitData.id"/>
                </van-cell>
                <van-field
                    :readonly="!!submitData.id"
                    clearable
                    clear-trigger="always"
                    :border="false"
                    class="left"
                    v-model="item.des"
                    rows="3"
                    autosize
                    type="textarea"
                    placeholder="请输入问题描述"
                    input-align="left"
                />
                <van-row style="padding: 0 5px;">
                  <van-col :span="24">
                    <upload-file
                        :file-list="item.files"
                        :del-btn-show="!submitData.id"
                        :upload-btn-show="!submitData.id"
                        @filesUpload="filesUpload($event, index)"
                        @delFile="delProblemFile"
                        :upload-path="'files/problem/temporary'"
                        :file-type="'all'"/>
                    <!--                <van-uploader v-model="item.files" @delete="uploaddelProblem" :name="index"-->
                    <!--                              :after-read="uploadReadProblem" :before-read="beforeUpload" :disabled="!isEdit"/>-->
                  </van-col>
                </van-row>
              </div>
            </van-cell-group>
          </template>
          <van-row class="btns" v-if="!isProblem">
            <van-col :span="24" v-if="isEdit">
              <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
            </van-col>
            <van-row gutter="10" v-else>
              <van-col :span="12">
                <van-button plain type="danger" size="large" round @click="deleteHandle">删除</van-button>
              </van-col>
              <van-col :span="12">
                <van-button type="info" size="large" round @click="isEdit=true">编辑</van-button>
              </van-col>
            </van-row>
          </van-row>
        </van-tab>

        <!--   关联问题详情     -->
        <van-tab title="关联信息" :name="'tab2'" v-if="!!submitData.id">
          <div class="list-info">
            <div class="cont">
              <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                        v-if="dataList.length > 0" offset="10">
                <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{ dataList.length }}</span>&nbsp;条数据</div>
                <div class="list-item" v-for="item in dataList" :key="item.id"
                     @click="goInfo(item.id,item.status,item.streetIsOpen)">
                  <div class="title">
                    <div class="title-left">
                      {{ item.name + ' | ' + item.createDate }}
                    </div>
                    <div class="title-right" :style="{color:item.status==0||item.status==10?'#ED4747':item.status==20?'#23D87B'
                :item.status==30?'#387FF5':'#0AB9B3'}">
                      {{ item.statusStr }}
                    </div>
                  </div>
                  <div class="content">
                    <van-image :src="item.url?item.url:require('@/assets/img/flower.png')" class="content-img">
                      <template slot="error">
                        <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                      </template>
                      <template slot="loading">
                        <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                      </template>
                    </van-image>
                    <div class="content-text">
                      <div class="top-text">{{ item.houseFullName }} <span
                          style="color:#387FF5;font-size:14px">({{ item.typeStr }})</span></div>
                      <div class="bottom-text">{{ item.content }}</div>
                    </div>
                    <van-icon name="arrow" color="#666" class="content-icon"/>
                  </div>
                </div>
              </van-list>
              <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
<!--    <res-list v-if="resListVisible" ref="resList" @checkRes="checkRes"></res-list>-->
<!--    <worker-list v-if="workerListVisible" ref="workUser" @checkWorker="checkWorker"></worker-list>-->
  </div>
</template>

<script>
import {beforeFileUpload, formatterDateTimeWithOutSec} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import {mapState, mapMutations} from 'vuex'
import resList from "./resList"
import {newDate} from "../../utils/utils";
import uploadFile from '@/components/upload/uploadFile'
import workerList from "./workerList"

export default {
  name: 'visit-add',
  data() {
    return {
      activeName: 'tab1',
      finished: false,
      dataList: [],
      typeListIndex: '',
      fileList: [],
      street: 0,
      orgId: 0,
      isEdit: false,
      i: '',
      visitTypeFlag: false,
      visible: true,
      resListVisible: false,
      workerListVisible: false,
      maxDate: '',
      file: {},
      detailData: {},
      active: 0,
      loading: false,
      checkedRes: [],
      checkedWorker: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      targetId: [],
      tasksVisitType: 0,
      codeSubmit: true,
      isTasks: false,
      problemSubmit: false,
      buildTypeShow: false,
      visitWayShow: false,
      visitTimeShow: false,
      visitTypeShow: false,
      buildingShow: false,
      problemShow: true,
      problemTypeShow: false,
      singleTypeShow: false,
      multipleTypeShow: false,
      sourceTypeShow: false,
      isOpen: false, //是否有问题反馈
      problemIds: [], // 未解决问题数
      problemInfoIds : '',
      selectMsg: {
        buildTypeStr: '请选择',
        visitWayStr: '请选择',
        visitTime: '请选择',
        visitTypeStr: '请选择',
        buildingStr: '请选择',
        placeStr: '请选择',
        description: ''
      },
      submitData: {
        id: '',
        buildType: '',
        visitWay: '',
        visitTime: '',
        visitType: '',
        address: '',
        placeId: '',
        visitSatisfaction: '2',
        problemList: [],
        detailedAddressValue: [],
        houseId: '',
        building: '',
        content: '',
        community: 0,
        subarea: 0
      },
      selectData: {
        buildTypeList: [{label: "有房户", value: "1"}, {label: "无房户", value: "2"}, {label: "场所", value: "3"}],
        visitWayList: [{label: "上门走访", value: "1"}, {label: "电话约访", value: "2"}, {
          label: "集中座谈式",
          value: "3"
        }, {label: "其他方式", value: "4"}],
        visitTypeList: [],
        buildingList: [],
        addressList: [],
        sourceTypeList: [{label: "上级督查", value: 1}, {label: "社区自查", value: 2}]
      },
      typeList: [{
        visitType: '',
        visitTypeName: '',
        visitDes: '',
        visitPicture: [],
        resList: [],
        newFileMessages: [],
        deleteFiles: [],
        placeholder: '请输入走访描述',
        placeholderClass: '',
        workUser: []
      }],
      sex: '',
      typeName: '',
      name: '',
      username: '',
      createUserName: [],
      isProblem: 0,
      // 从什么页面跳转而来
      fromPage: ''
    }
  },
  watch: {
    'selectData.buildingList': {
      handler(newarr, oldarr) {
        if (this.$route.query.ids) {
          if (newarr !== []) {
            let selectedOptions = []
            let ids = this.$route.query.ids.split('-')
            ids.map(item => {
              if (item) {
                return item
              }
            })
            this.getIdsList(ids, 0, this.selectData.buildingList, selectedOptions)
            let obj = {
              selectedOptions,
              value: selectedOptions[selectedOptions.length - 1].value
            }
            this.buildingFinish(obj)
          }
        }
      }
    },
    'problemVisitIds': {
      handler(newarr, oldarr) {
        if (this.problemVisitIds) {
          this.getVisitProblem()
        }
      }
    }
  },
  computed: {...mapState(['visitId','problemVisitIds'])},
  components: {
    resList,
    uploadFile,
    workerList
  },
  methods: {
    ...mapMutations,
    // 查看关联问题详情
    goInfo(id, status, streetIsOpen) {
      let data = {'id': id, 'status': status, 'streetIsOpen': streetIsOpen}
      this.$store.commit('setProblemData', data)
      this.$router.push('/problem-info')
    },
    upload(files, index) {
      this.typeList[index].visitPicture = files
      this.typeList[index].newFileMessages = files.filter(item => !item.id)
    },
    delFile(id, index) {
      this.typeList[index].deleteFiles.push(id)
    },
    filesUpload(files, index) {
      this.submitData.problemList[index].files = files
      this.submitData.problemList[index].problemUrls = files
    },
    delProblemFile(id, index) {
      this.typeList[index].deleteFiles.push(id)
    },
    getDataList(){},
    getUser() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.$globalData.userInfo.userId,
          orgId: this.orgId
        }, false)
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.sex = data.userManager.sex
          this.typeName = data.userManager.typeName
          this.name = data.userManager.name
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 查看户信息
    toHouseInfo() {
      this.$store.commit('setHouseId', this.submitData.houseId)
      this.$router.push('./house-info')
    },
    // 跳转问题列表
    toProblem(){
      if (this.problemIds.length > 0) {
        localStorage.setItem('isRefresh', 1)
        this.$router.push({path: '/problem', query: {ids: this.problemIds.toString()}})
      }
    },
    init() {
      this.getDictTypeList('problemType')
      this.getDictTypeList('visitType')
      this.getHasProblem()
      if (this.submitData.id) {
        this.isEdit = false
        this.getInfo()
      } else {
        this.isEdit = true
      }
      let nowDate = new Date()
      this.maxDate = nowDate
      if (this.street == 12985) {
        this.minDate = new Date(nowDate.getFullYear(), nowDate.getMonth())
      } else {
        this.minDate = new Date(nowDate.getFullYear() - 1, nowDate.getMonth())
      }
      this.selectMsg.visitTime = formatterDateTimeWithOutSec(nowDate)
      this.submitData.visitTime = nowDate
      if (this.submitData.buildType !== '' && (this.submitData.detailedAddressValue.length > 0 || this.submitData.placeId !== '') && this.submitData.visitWay !== '' && (this.submitData.buildType == 1 || this.submitData.buildType == 3)) {
        this.getVisitProblem()
      }
    },
    // 获取详情
    getInfo() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/visit/info'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: this.submitData.id
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let list = data.commonAppVisit
          var detailedAddressValue = []
          if (list.subarea) {
            detailedAddressValue.push(list.subarea)
          }
          if (list.buildingId) {
            detailedAddressValue.push(list.buildingId)
          }
          if (list.unit) {
            detailedAddressValue.push(list.unit)
          }
          if (list.houseId) {
            detailedAddressValue.push(list.houseId)
          }
          this.submitData.detailedAddressValue = detailedAddressValue
          this.submitData.visitSatisfaction = list.visitSatisfaction ? list.visitSatisfaction.toString() : ''
          this.submitData.visitWay = list.serviceType
          this.selectMsg.visitWayStr = list.serviceTypeStr
          this.submitData.visitTime = newDate(list.createTime)
          this.selectMsg.visitTime = list.createTime
          this.submitData.houseId = list.houseId
          this.submitData.address = list.placeStr || list.fullLocation
          this.typeList[0].visitType = list.visitType
          this.typeList[0].visitTypeName = list.visitTypeStr
          this.selectMsg.visitTypeStr = list.visitTypeStr
          this.typeList[0].visitDes = list.visitDes
          this.typeList[0].id = list.id
          this.typeList[0].resList = list.users
          this.submitData.placeId = list.placeId
          this.username = list.userName
          this.submitData.community = list.community
          if (list.problemIds) {
            this.problemInfoIds = list.problemIds
            this.dataList = list.problems
            // let problemList = []
            // let array = list.problems
            // for (let i in array) {
            //   let problem = array[i]
            //   let files = problem.problemFiles
            //   let urls = []
            //   for (let i in files) {
            //     let file = files[i]
            //     let u
            //     u = getImageStream(files[i].filePath)
            //     let url = {
            //       uid: urls.length,
            //       id: file.id,
            //       status: 'done',//uploading上传中，done上传完成，error上传失败
            //       fileType: file.type,
            //       relativePath: file.filePath,
            //       url: u,
            //       realUrl: file.filePath.replace(/\\/g, "/")
            //     }
            //     urls.push(url)
            //   }
            //   let problems = {
            //     id: problem.id,
            //     type: problem.type,
            //     typeTitle: problem.typeStr,
            //     des: problem.content,
            //     problemUrls: urls,
            //     files: urls,
            //     isRelation: true,
            //     opened: true,
            //     status: problem.statusStr
            //   }
            //   problemList.push(problems)
            // }
            // this.submitData.problemList = problemList
          }
          this.finished = true
          if (list.isCodePhone === 0) {
            this.codeSubmit = true
          } else {
            this.codeSubmit = false
          }
          let files = list.filesPath
          let urls = []
          for (let i in files) {
            let file = files[i]
            let u
            u = getImageStream(files[i].filePath)
            let url = {
              uid: urls.length,
              id: file.id,
              status: 'done',//uploading上传中，done上传完成，error上传失败
              fileType: file.type,
              relativePath: file.filePath,
              url: u,
              realUrl: file.filePath.replace(/\\/g, "/")
            }
            urls.push(url)
          }
          this.typeList[0].visitPicture = urls
          this.typeList[0].newFileMessages = urls
          this.createUserName = list.createUserName.split(',')
          this.getVisitProblem()
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 是否有问题反馈
    getHasProblem() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/getAppIsOpen'),
        method: 'get',
        params: this.$http.adornParams({
          code: 'sjgl_wtjl',
          orgId: this.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.isOpen = data.isOpen
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getVisitProblem(){
      if (this.submitData.buildType == 2) {
        if (!this.submitData.id) {
          this.typeList[0].userIds = this.checkedRes.map(item => {
            return item.id
          }).toString()
        } else {
          this.typeList[0].userIds = this.typeList[0].resList.map(item => {
            return item.id
          }).toString()
        }
      }
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/visit/getVisitProblemIds'),
        method: 'post',
        data: {
          visitBody: this.submitData.buildType == 3 ? 2 : 1,
          placeId: this.submitData.buildType == 3 ? this.submitData.placeId : undefined,
          detailedAddress: this.submitData.detailedAddressValue,
          serviceType: this.submitData.visitWay || 1,
          createTime: formatterDateTimeWithOutSec(this.submitData.visitTime),
        }
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.problemIds = data.list
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取房屋列表
    getBuildingList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/liveWhichTree'),
        method: 'get',
        params: this.$http.adornParams({
          communityId: this.orgId,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.buildingList = data.liveWhichTree
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // tab栏切换
    tabsChange(name, title) {
      this.typeList.forEach(item => {
        if (item.visitDes.trim() === '') {
          item.resList.forEach(val => {
            val['select'] = false
          })
          item.newFileMessages = []
          item.visitPicture = []
          item.userIds = ''
        }
      })
    },
    // 提交
    submit() {
      // 校验
      if (this.submitData.buildType === '') {
        return this.$toast.fail('失败:请先选择房屋类别')
      }
      if (this.submitData.visitWay === '') {
        return this.$toast.fail('失败:请选择服务方式')
      }
      if (this.submitData.visitTime === '') {
        return this.$toast.fail('失败:走访时间不能为空')
      }
      // 有房户校验
      if (this.submitData.buildType == 1) {
        if (this.submitData.detailedAddressValue.length === 0) {
          return this.$toast.fail('失败:请选择详细地址')
        }
        if (this.typeList.length === 1) {
          if (this.typeList[0].visitType === '' || this.typeList[0].visitType === '0') {
            return this.$toast.fail('失败:请选择走访类型')
          }
          if (this.typeList[0].visitDes === '') {
            return this.$toast.fail('失败:走访描述不能为空')
          }
          if (this.typeList[0].visitDes.length < 15) {
            return this.$toast.fail('失败:走访描述不能少于15个字')
          }
          if (this.typeList[0].userIds === '') {
            return this.$toast.fail('失败:请选择走访居民')
          }
        } else {
          let initList = this.typeList
          let isEmpty = true
          let isResDesEmpty = true
          let isResEmpty = false
          let isDesEmpty = false
          let visitTypeName = ''
          for (var i = 0; i < initList.length; i++) {
            if (this.isTasks || initList[i].userIds && initList[i].userIds != '') {
              visitTypeName = initList[i].visitTypeName
              isEmpty = false
              if (this.isTasks || initList[i].visitDes && initList[i].visitDes.split('').length > 15) {
                isResEmpty = false
              } else {
                isResEmpty = true
                break
              }
            }
            if (initList[i].visitDes && initList[i].visitDes.split('').length > 15) {
              visitTypeName = initList[i].visitTypeName
              isResDesEmpty = false
              if (this.isTasks || initList[i].userIds && initList[i].userIds != '') {
                isDesEmpty = false
              } else {
                isDesEmpty = true
                break
              }
            }
          }
          if (isEmpty && !isDesEmpty) {
            return this.$toast.fail('失败:请选择走访居民')
          } else if (isDesEmpty) {
            return this.$toast.fail(`失败:请选择${visitTypeName}的居民`)
          }
          if (isResDesEmpty && !isResEmpty) {
            return this.$toast.fail('失败:走访描述不能为空')
          } else if (isResEmpty) {
            return this.$toast.fail(`失败:请输入${visitTypeName}不少于15字的走访描述`)
          }
        }
      }
      // 无房户校验
      if (this.submitData.buildType == 2) {
        if (!this.submitData.id) {
          if (this.checkedRes.length === 0) {
            return this.$toast.fail('失败:请选择走访居民')
          }
          if (this.typeList[0].visitType === '') {
            return this.$toast.fail('失败:请选择走访类型')
          }
          if (this.typeList[0].visitDes === '') {
            return this.$toast.fail('失败:走访描述不能为空')
          }
          if (this.typeList[0].visitDes.length < 15) {
            return this.$toast.fail('失败:走访描述不能少于15字')
          }
          this.typeList[0].userIds = this.checkedRes.map(item => {
            return item.id
          }).toString()
        } else {
          this.typeList[0].userIds = this.typeList[0].resList.map(item => {
            return item.id
          }).toString()
        }
      }
      // 场所校验
      if (this.submitData.buildType == 3) {
        if (!this.submitData.placeId) {
          return this.$toast.fail('失败:请选择走访场所')
        }
        if (this.typeList[0].visitType === '') {
          return this.$toast.fail('失败:请选择走访类型')
        }
        if (this.typeList[0].visitDes === '') {
          return this.$toast.fail('失败:走访描述不能为空')
        }
      }
      // 问题校验
      if (this.submitData.problemList.length != 0 && this.problemSubmit) {
        for (var i = 0; i < this.submitData.problemList.length; i++) {
          if (!this.submitData.problemList[i].type) {
            return this.$toast.fail('失败:请选择问题类型')
          }
          if (!this.submitData.problemList[i].des) {
            return this.$toast.fail('失败:请输入问题描述')
          }
        }
      }
      this.loading = true
      this.$toast.loading({duration: 0, message: '提交中...', forbidClick: true,});
      this.typeList.forEach((val, i) => {
        if (val.newFileMessages.length > 0) {
          val.newFileMessages = val.newFileMessages.filter((item, index) => {
            this.typeList[i].visitPicture[index] = {
              ...item,
              ...this.typeList[i].visitPicture[index]
            }
            return !item.id || item.id == null
            // if (item.id && item.id !== null) {
            //   this.typeList[i].newFileMessages.splice(index, 1)
            // }
          })
        }
      })

      // this.typeList.forEach(item => {
      //   if (item.visitPicture) {
      //     item.visitPicture.forEach((val, index) => {
      //       if (val.id && val.id !== null) {
      //         item.visitPicture.splice(index, 1)
      //       }
      //     })
      //     item.newFileMessages = item.visitPicture
      //   }
      // })
      let problemList = this.submitData.problemList
      let problems = []
      for (let i in problemList) {
        let problem = problemList[i]
        let problemObject = {
          type: problem.type,
          sourceType: problem.sourceType,
          id: problem.id || undefined,
          des: problem.des,
          files: problem.files,
          isRelation: problem.isRelation
        }
        problems.push(problemObject)
      }
      let ids = this.problemVisitIds !== '' ? this.problemVisitIds.split(',') : ''
      this.submitData.visitSatisfaction = this.codeSubmit ? -1 : this.submitData.visitSatisfaction
      this.$httpApp({
        url: this.$httpApp.adornUrl(`/wxapp/visit/save`),
        method: 'post',
        data: {
          id: this.typeList.length == 1 ? this.submitData.id : '',
          visitBody: this.submitData.buildType == 3 ? 2 : 1,
          placeId: this.submitData.placeId,
          detailedAddress: this.submitData.detailedAddressValue,
          serviceType: this.submitData.visitWay || 1,
          visitList: this.typeList.map(item => {return {...item, workUser: item.workUser.map(val => {return val.value}).concat([this.$globalData.userInfo.userId])}}),
          problemList: !this.submitData.id ? problems : [],
          createTime: formatterDateTimeWithOutSec(this.submitData.visitTime),
          visitSatisfaction: this.submitData.visitSatisfaction,
          isCodePhone: this.codeSubmit ? 0 : 1,
          problemIds: this.submitData.id ? this.problemInfoIds : ids.slice(0, ids.length - 1).toString(),
          community: this.submitData.id ? this.submitData.community : undefined,
          subarea: this.submitData.subarea || 0
        }
      }).then(({data}) => {
        this.$toast.clear()
        if (data.code == 0) {
          if (!this.submitData.id && this.fromPage != 'userRes' && this.fromPage != 'searchResult') {
            localStorage.setItem('isRefresh', 1)
          }
          let id = data.data.join(',')
          let sex = this.sex
          let typeName = this.typeName.split(',')[0]
          let name = this.name
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onClose: () => {
              if (this.codeSubmit == true && this.street == 12985) {
                setTimeout(() => {
                  this.$router.push({path: './code', query: {id: id, sex: sex, typeName: typeName, name: name}})
                }, 1500)
              } else {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1000)
              }
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg)
        }
      })
      this.loading = false
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$httpApp({
          url: this.$httpApp.adornUrl('/wxapp/visit/delete'),
          method: 'post',
          params: this.$httpApp.adornParams({
            id: this.submitData.id
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            localStorage.setItem('isRefresh', 1)
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 房屋类别选择
    buildTypeConfirm(value) {
      if (value) {
        if (value.value == 1) {
          this.getBuildingList()
        }
        this.selectMsg.buildTypeStr = value.label
        this.submitData.buildType = value.value
        if (this.submitData.buildType !== '' && (this.submitData.detailedAddressValue.length > 0 || this.submitData.placeId !== '') && this.submitData.visitWay !== '' && (this.submitData.buildType == 1 || this.submitData.buildType == 3)) {
          this.getVisitProblem()
        }
      }
      this.buildTypeShow = false
    },
    // 服务方式选择
    visitWayConfirm(value) {
      if (value) {
        this.selectMsg.visitWayStr = value.label
        this.submitData.visitWay = value.value
        if (this.submitData.buildType !== '' && (this.submitData.detailedAddressValue.length > 0 || this.submitData.placeId !== '') && this.submitData.visitWay !== '' && (this.submitData.buildType == 1 || this.submitData.buildType == 3)) {
          this.getVisitProblem()
        }
      }
      this.visitWayShow = false
    },
    // 走访类型选择
    visitTypeConfirm(value) {
      if (value) {
        this.selectMsg.visitTypeStr = value.label
        this.submitData.visitType = value.value
        this.typeList[0].visitType = value.value
        if (value.label == "就创走访") {
          this.typeList[0].placeholder = "请询问居民专业能力、业余特长、就业需求、是否有职称和资格证书，并在居民管理内的居民就业信息中填写变更内容"
          this.typeList[0].placeholderClass = 'redTip'
        } else {
          this.typeList[0].placeholder = '请输入走访描述'
          this.typeList[0].placeholderClass = ''
        }
      }
      this.visitTypeShow = false
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    // 详细地址选择
    buildingFinish(value) {
      if (value) {
        let options = value.selectedOptions
        this.submitData.detailedAddressValue = options.map(item => {
          return item.value
        })
        this.selectMsg.buildingStr = ''
        let houseId = -1;
        let option = options[options.length - 1];
        if (option.depth == 2 && option.children == null) {
          houseId = option.parameter
        } else if (option.depth == 3 && option.children == null) {
          houseId = -1
        } else if (option.depth == 4 && option.children == null) {
          houseId = option.value
        } else {
          houseId = -1
        }
        this.submitData.houseId = houseId
        if (option.depth == 2 && option.children == null) {
          houseId = option.parameter
        }
        options.map(item => {
          this.selectMsg.buildingStr += item.label
        })
        this.submitData.address = this.selectMsg.buildingStr
        this.getVisitType()
        if (this.submitData.buildType !== '' && (this.submitData.detailedAddressValue.length > 0 || this.submitData.placeId !== '') && this.submitData.visitWay !== '' && (this.submitData.buildType == 1 || this.submitData.buildType == 3)) {
          this.getVisitProblem()
        }
      }
      this.buildingShow = false
    },
    // 获取走访方式
    getVisitType() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/visit/getTypeByhouseId'),
        method: 'get',
        params: this.$httpApp.adornParams({
          houseId: this.submitData.houseId
        })
      }).then(({data}) => {
        console.log(this.tasksVisitType)
        if (data && data.code === 0) {
          let typeList = []
          data.list.map((item, index) => {
            if (item.userList) {
              let visitDes = ''
              let visitPicture = []
              if (this.typeList[index]) {
                visitDes = this.typeList[index].visitDes
                visitPicture = this.typeList[index].visitPicture
              }
              item.userList.forEach(val => {
                this.targetId.forEach(item2 => {
                  if (item2 == val.id && (this.tasksVisitType == item.typeId || !item.typeId || !this.tasksVisitType)) {
                    val['select'] = true
                    if (!item.typeId || !this.tasksVisitType) {
                      this.visitTypeFlag = false
                      if (this.tasksVisitType) {
                        this.selectData.visitTypeList.forEach(item => {
                          if (this.tasksVisitType == item.value) {
                            this.visitTypeConfirm(item)
                          }
                        })
                      }
                    }
                  }
                })
              })
              let placeholder = '请输入走访描述'
              let placeholderClass = ''
              if (item.typeName == "就创走访") {
                placeholder = "请询问居民专业能力、业余特长、就业需求、是否有职称和资格证书，并在居民管理内的居民就业信息中填写变更内容"
                placeholderClass = 'redTip'
              }
              typeList.push({
                visitType: item.typeId ? String(item.typeId) : String(this.tasksVisitType),
                visitTypeName: item.typeName || '请选择',
                visitDes: visitDes,
                visitPicture: visitPicture,
                resList: item.userList,
                newFileMessages: [],
                deleteFiles: [],
                placeholder: placeholder,
                placeholderClass: placeholderClass,
                workUser: []
              })
            }
          })
          this.typeList = typeList
          this.typeList.forEach(item2 => {
            let userIds = []
            let select = false
            if (item2.resList) {
              item2.resList.map(item => {
                if (item.select) {
                  userIds.push(item.id)
                  select = true
                }
              })
              console.log(userIds);
              item2['userIds'] = userIds.toString()
              item2['select'] = select
            }
          });
          if (this.isTasks) {
            this.typeList = this.typeList.filter(array => {
              return array.select
            })
          }
          console.log(this.typeList)
          if (data.list.length > 1) {
            if (this.$route.query.dictId !== '0') {
              this.multipleTypeShow = true
              this.singleTypeShow = false
            } else {
              this.multipleTypeShow = false
              this.singleTypeShow = true
            }
          } else {
            this.multipleTypeShow = false
            this.singleTypeShow = true
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取字典
    getDictTypeList(code) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: code,
          orgId: this.orgId,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (code === 'problemType') {
            this.selectData.problemList = data.dicts
          } else if (code === 'visitType') {
            this.selectData.visitTypeList = data.dicts
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    resClick(index, i) {
      if (this.isTasks) {
        return
      }
      let res = this.typeList[index].resList[i]
      if (res['select']) {
        res['select'] = false
      } else {
        res['select'] = true
      }
      this.typeList[index].resList[i]['select'] = res['select']
      this.$forceUpdate()
      let userIds = []
      this.typeList[index].resList.map(item => {
        if (item.select) {
          userIds.push(item.id)
        }
      })
      this.typeList[index]['userIds'] = userIds.toString()
    },
    timeConfirm(value) {
      if (value) {
        this.selectMsg.visitTime = formatterDateTimeWithOutSec(value)
        this.submitData.visitTime = formatterDateTimeWithOutSec(value)
      }
      this.visitTimeShow = false
    },
    // 选择无房居民
    selectRes() {
      // this.visible = false
      // this.resListVisible = true
      // this.$nextTick(() => {
      //   if (this.checkedRes.length !== 0) {
      //     this.$refs.resList.checkedRes = this.checkedRes.map(item => {
      //       return item.id
      //     })
      //     this.$refs.resList.checkResList = this.checkedRes
      //   }
      // })
      if (this.checkedRes.length !== 0) {
        this.$store.commit('setResList', this.checkedRes)
      }
      this.$router.push('./res-list')
    },
    // 选择走访人
    selectWorker(index) {
      // this.visible = false
      // this.workerListVisible = true
      // this.$nextTick(() => {
      //   if (index) {
      //     this.typeListIndex = index
      //     if (this.typeList[index].workUser.length !== 0) {
      //       this.$refs.workUser.checkedWorker = this.typeList[index].workUser.map(item => {
      //         return item.value
      //       })
      //       this.$refs.workUser.checkWorkerList = this.typeList[index].workUser
      //     }
      //   } else {
      //     if (this.checkedWorker.length !== 0) {
      //       this.$refs.workUser.checkedWorker = this.checkedWorker.map(item => {
      //         return item.value
      //       })
      //       this.$refs.workUser.checkWorkerList = this.checkedWorker
      //     }
      //   }
      // })
      this.typeListIndex = ''
      if (index) {
        this.typeListIndex = index
        if (this.typeList[index].workUser.length !== 0) {
          this.$store.commit('setResList', this.typeList[index].workUser)
        }
      } else {
        if (this.checkedWorker.length !== 0) {
          this.$store.commit('setResList', this.checkedWorker)
        }
      }
      this.$router.push('./worker-list')
    },
    checkRes(msg) {
      this.resListVisible = false
      this.visible = true
      if (msg) {
        this.checkedRes = msg
      }
    },
    checkWorker(msg) {
      this.workerListVisible = false
      this.visible = true
      if (msg) {
        this.typeListIndex ? this.typeList[this.typeListIndex].workUser = msg : this.checkedWorker = this.typeList[0].workUser = msg
      }
    },
    // 选择场所
    selectPlace() {
      this.$router.push('./place-list')
    },
    // 选择完场所
    checkPlace(msg) {
      this.visible = true

    },
    // 是否上报问题
    problemSubmitShow() {
      if (this.problemSubmit && this.submitData.problemList.length === 0) {
        this.addProblem()
      } else {
        this.submitData.problemList = []
      }
    },
    // 选择问题来源
    checkSourceType(index) {
      this.sourceTypeShow = true
      this.i = index
    },
    // 选择问题类型
    checkProblemType(index) {
      this.problemTypeShow = true
      this.i = index
    },
    // 问题来源选择
    sourceTypeConfirm(value) {
      if (value) {
        this.submitData.problemList[this.i].sourceTypeStr = value.label
        this.submitData.problemList[this.i].sourceType = value.value
      }
      this.sourceTypeShow = false
    },
    // 问题类型选择
    problemTypeFinish(value) {
      if (value) {
        this.submitData.problemList[this.i].typeTitle = value.selectedOptions[value.selectedOptions.length - 1].label
        this.submitData.problemList[this.i].type = value.value
      }
      this.problemTypeShow = false
    },
    // 新增问题
    addProblem() {
      var problem = {
        type: 0,
        typeTitle: '请选择',
        sourceType: 0,
        sourceTypeStr: '请选择',
        des: '',
        problemUrls: [],
        files: [],
        opened: true,
        isRelation: true
      }
      this.submitData.problemList.push(problem)
    },
    // 删除问题
    deleteProblem(i) {
      this.submitData.problemList.splice(i, 1)
      if (this.submitData.problemList.length === 0) {
        this.problemSubmit = false
      }
    },
    formInfo() {
      this.submitData.id = this.$route.query.id || ''
      this.submitData.buildType = this.$route.query.type || ''
      this.selectMsg.buildTypeStr = this.submitData.buildType == 1 ? '有房户'
        : this.submitData.buildType == 2 ? '无房户' : this.submitData.buildType == 3 ? '场所' : '请选择'
      this.submitData.orgId = this.orgId,
        this.submitData.userId = this.$globalData.userInfo.userId
      if (this.submitData.id && this.submitData.buildType == 1) {
        this.singleTypeShow = true
      }
    },
    formTasks() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/dailytasks/executionTask'),
        method: 'post',
        data: this.$http.adornData({
          dictId: this.$route.query.dictId,
          type: this.$route.query.type,
          targetId: this.$route.query.targetId,
          street: this.$route.query.street,
          houseId: this.$route.query.houseId,
          targetStr: this.$route.query.targetStr
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.visitEntity.houseId) {
            this.submitData.buildType = 1
            this.selectMsg.buildTypeStr = '有房户'
            this.submitData.houseId = data.visitEntity.houseId
            this.tasksVisitType = data.visitEntity.visitType
            this.getBuildingList()
            this.getVisitType()
            this.submitData.detailedAddressValue = [data.visitEntity.community, data.visitEntity.buildingId, data.visitEntity.unitId, data.visitEntity.houseId]
            this.selectMsg.buildingStr = data.visitEntity.address
            this.targetId = data.visitEntity.userIds.split(',')
          } else {
            this.submitData.buildType = 2
            this.selectMsg.buildTypeStr = '无房户'
            this.submitData.visitType = data.visitEntity.visitType
            this.typeList[0].visitType = data.visitEntity.visitType
            this.selectMsg.visitTypeStr = data.visitEntity.visitTypeStr
            this.typeList[0].visitTypeName = data.visitEntity.visitTypeStr
            this.username = data.visitEntity.userName
            this.checkedRes.push({id: data.visitEntity.userIds})
          }
          this.isTasks = true
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getIdsList(ids, index, arr, list) {
      let i = arr.findIndex(item => ids[index] == item.value)
      if (i !== -1) {
        list.push(arr[i])
      }
      if (arr[i].children && ids[index++]) {
        this.getIdsList(ids, index++, arr[i].children, list)
      }
    },
    getHouseInfo(houseId){
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: houseId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          let list = data.buildingHouse
          var detailedAddressValue = []
          if (list.subarea) {
            detailedAddressValue.push(list.subarea)
          }
          if (list.buildingId) {
            detailedAddressValue.push(list.buildingId)
          }
          if (list.unitId) {
            detailedAddressValue.push(list.unitId)
          }
          if (houseId) {
            detailedAddressValue.push(houseId)
          }
          this.submitData.detailedAddressValue = detailedAddressValue
          this.selectMsg.buildingStr = list.subareaName + list.fullName
          this.submitData.address = list.subareaName + list.fullName
          this.submitData.houseId = list.id
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    }
  },
  activated () {
    if (this.$store.state.placeInfo.placeValue) {
      this.submitData.placeId = this.$store.state.placeInfo.placeValue
      this.submitData.address = this.$store.state.placeInfo.address
      this.selectMsg.placeStr = this.$store.state.placeInfo.placeTitle
      this.submitData.subarea = this.$store.state.placeInfo.subarea
      if (this.submitData.buildType !== '' && (this.submitData.detailedAddressValue.length > 0 || this.submitData.placeId !== '') && this.submitData.visitWay !== '' && (this.submitData.buildType == 1 || this.submitData.buildType == 3)) {
        this.getVisitProblem()
      }
    }
    if (this.$store.state.resList.length > 0) {
      this.checkedRes = this.$store.state.resList
    }
    if (this.$store.state.workerList.length > 0) {
      this.typeListIndex ? this.typeList[this.typeListIndex].workUser = this.$store.state.workerList : this.checkedWorker = this.typeList[0].workUser = this.$store.state.workerList
    }
  },
  created() {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.isHouseAdd = false
    this.street = this.$globalData.userInfo.street
    this.orgId = this.$orgId
    this.getUser()
    if (this.street !== 12985){
      this.codeSubmit = false
    }
    // 获取从什么页面而来
    if (this.$route.query.fromPage) {
      this.fromPage = this.$route.query.fromPage
    }
    if (this.$route.query && this.$route.query.reAdd) {
      // 再走一次
      let visitInfo = this.$route.query
      this.selectMsg.visitWayStr = visitInfo.serviceTypeStr
      this.submitData.visitWay = visitInfo.serviceType
      if (visitInfo.houseId) {
        this.buildTypeConfirm({label: "有房户", value: "1"})
        var detailedAddressValue = []
        if (visitInfo.subarea) {
          detailedAddressValue.push(visitInfo.subarea)
        }
        if (visitInfo.buildingId) {
          detailedAddressValue.push(visitInfo.buildingId)
        }
        if (visitInfo.unit) {
          detailedAddressValue.push(visitInfo.unit)
        }
        if (visitInfo.houseId) {
          detailedAddressValue.push(visitInfo.houseId)
        }
        this.submitData.detailedAddressValue = detailedAddressValue
        this.selectMsg.buildingStr = visitInfo.fullLocation
        this.submitData.address = visitInfo.fullLocation
        this.submitData.houseId = visitInfo.houseId
        this.getVisitType()
      } else if (visitInfo.visitBody == 1) {
        this.buildTypeConfirm({label: "无房户", value: "2"})
        this.checkedRes = visitInfo.userIds.split(',').map((item, index) => {
          return {
            id: item,
            name: visitInfo.username.split(',')[index]
          }
        })
      } else if (visitInfo.visitBody == 2) {
        this.buildTypeConfirm({label: "场所", value: "3"})
        this.submitData.address = visitInfo.placeStr
        this.submitData.placeId = visitInfo.placeId
        this.selectMsg.placeStr = visitInfo.placeStr
      }
    } else if (this.$route.query && !this.$route.query.reAdd) {
      if (this.$route.query.tasks) {
        this.formTasks()
      } else {
        this.formInfo()
      }
      if (this.$route.query.ids) {
        this.buildTypeConfirm({label: "有房户", value: "1"})
      }
      if (this.$route.query.houseId) {
        this.buildTypeConfirm({label: "有房户", value: "1"})
        this.isHouseAdd = true
        var detailedAddressValue = []
        let list = this.$route.query
        if (list.subarea) {
          detailedAddressValue.push(list.subarea)
        }
        if (list.buildingId) {
          detailedAddressValue.push(list.buildingId)
        }
        if (list.unitId) {
          detailedAddressValue.push(list.unitId)
        }
        if (list.houseId) {
          detailedAddressValue.push(list.houseId)
        }
        this.getHouseInfo(this.$route.query.houseId)
        this.submitData.detailedAddressValue = detailedAddressValue
        this.selectMsg.buildingStr = list.buildingStr
        this.submitData.address = list.buildTypeStr
        this.submitData.houseId = list.houseId
        this.getVisitType()
      } else if (!this.$route.query.houseId && this.$route.query.userId){
        this.buildTypeConfirm({label: "无房户", value: "2"})
        this.checkedRes.push({id: Number(this.$route.query.userId), name: this.$route.query.userName})
      }
      this.$store.commit('setPlaceInfo', {})
      this.$store.commit('setResList', [])
      this.$store.commit('setWorkerList', [])
    }
    this.init()
  },
  beforeDestroy() {
    this.$store.state.placeInfo = {}
    this.$store.state.problemVisitIds = ''
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style scoped>
.visitInfo >>> .van-tabs__nav {
  margin: 0 20px;
  /*background-color: #F5F5F5;*/
}

.visitInfo >>> .van-tabs__line {
  width: 116px;
  height: 2px;
  background-color: #5A86FB;
}

.visitInfo >>> .van-tabs__nav--line {
  height: 88px;
  padding-bottom: 22px;
}

.visitInfo >>> .van-tab__pane {
  margin-top: 20px;
  padding: 0 30px;
}

.visitInfo >>> .van-tab {
  font-size: 22px;
  margin: 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.visitInfo >>> .van-tab--active {
  color: #387FF5;
  background-color: #ffffff;
  /*border-radius: 40px;*/
}


.visitInfo >>> .van-tabs__wrap {
  height: 88px;
}

.cellClass {
  padding: 20px 5px 10px;
}
</style>
<style lang="scss" scoped>
.info {
  ::v-deep .van-cell__title {
    color: #666666;
  }

  ::v-deep .van-cell__value {
    color: #333333;
  }
}

.upload {
  padding-left: 30px;
}

.redTip {
  ::placeholder { //通过这个设置
    color: red !important;
  }
}

.userBtn {
  height: 52px;
  margin: 0 6px 6px 0;
  padding: 0 44px;
  color: #333333;
  border-color: #F5F5F5;
  border-radius: 12px;
  background-color: #F5F5F5;
}

.checkClass {
  color: #387FF5;
  background-color: rgba(56, 127, 245, 0.12);
}

.check {
  color: #3F8BFC !important;

  .van-button {
    width: 92px;
    height: 52px;
    font-size: 24px;
    border-color: #4E7CF5;
    border-radius: 12px;
  }

  ::v-deep .van-button--normal {
    padding: 0;
  }
}
//.tab {
//  ::v-deep .van-tabs__line {
//    opacity: 0;
//  }
//  ::v-deep .van-tabs__wrap {
//    height: 0;
//  }
//}
</style>
